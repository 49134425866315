body {
  margin: 0;
  font-family: 'Compton';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eaf2ff !important;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ComptonBold';
  font-style: normal;
  font-weight: normal;
  src : url('./Fonts/Campton-BoldDEMO.eot');
  src:url('./Fonts/Campton-BoldDEMO.ttf') format('truetype'),
      url('./Fonts/Campton-BoldDEMO.woff') format('woff'),
      url('./Fonts/Campton-BoldDEMO.woff2') format('woff2');
}

@font-face {
  font-family: 'ComptonLight';
  font-style: normal;
  font-weight: normal;
  src : url('./Fonts/Campton-LightDEMO.eot');
  src:url('./Fonts/Campton-LightDEMO.ttf') format('truetype'),
      url('./Fonts/Campton-LightDEMO.woff') format('woff'),
      url('./Fonts/Campton-LightDEMO.woff2') format('woff2');

}


@font-face {
  font-family: 'Compton Book';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Book'),url('./Fonts/CamptonBook.woff') format('woff');
}

@font-face {
  font-family: 'Compton Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Extra Bold'),url('./Fonts/CamptonExtraBold.otf') format('otf') ,
     url('./Fonts/CamptonExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Compton Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Medium'),url('./Fonts/CamptonMedium.woff') format('woff');
}

@font-face {
  font-family: 'Compton SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Campton SemiBold'),url('./Fonts/CamptonSemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Compton Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Campton Thin'),url('./Fonts/CamptonThin.woff') format('woff');
}

