.App {
  background: #eaf2ff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
#root {
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-height {
  height: 100vh;
}

.Rectangle {
  /* width: 100%; */
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.beforeLogin {
  padding: 45px 130px 60px 130px !important;
  display: flex;
  flex-direction: column;
  min-height: 30vh;
}

@media all and (max-width: 420px) {
  .Rectangle {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

h3 {
  font-family: "ComptonBold";
}

/* input[type="text"] {
  background: #f7f7f7 !important;
} */

.main {
  width: 100% !important;
  display: flex;
  /* flex-direction: column; */
  font-family: "SF Pro Text";
  min-height: 40vh;
  flex: 1;
  padding: 12px 0;
  height: 100vh;
  max-width: 100% !important;
}

#fullBtn {
  background-color: #0189cc;
  color: #ffffff;
}

.sidebar-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.sidebar-label {
  cursor: pointer;
  /* margin: 8px 0; */
  line-height: 40px;
  padding: 0px 15px !important;
}
.sidebar-label.active {
  background-color: #eaf2ff;
  border-radius: 30px;
  color: #003f63 !important;
  cursor: pointer;
}
.sidebar-padd {
  padding: 4px 16px;
  cursor: pointer;
  font-size: 14px;
}

p {
  color: #003f63;
}
h6 {
  color: #003f63 !important;
  font-family: "ComptonBold";
  font-size: 20px !important;
}
h5 {
  color: #003f63 !important;
  font-family: "ComptonBold";
}

.border-0 {
  border: none;
}

.sidebar-para {
  color: #44b2dc;
  cursor: pointer;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.admin-logo {
  width: 40px;
  height: 40px;
}

.logo_image {
  width: 250px;
  height: 50px;
  opacity: 1;
  margin-top: 25px;
  margin-bottom: 30px;
}
.sidebar-logo {
  width: 174px;
  height: 37px;
  opacity: 1;
}
.Sign-in-to-account {
  color: #003f63;
  font-size: 22px;
}
* {
  font-family: "Compton Medium";
}
a {
  color: #0089cc !important;
  text-decoration: none !important;
  cursor: pointer;
}
.eyeIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  cursor: pointer;
}
.eyeIconNewPass {
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 150px;
}

.eyeIconConfirm {
  width: 20px;
  height: 20px;
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 204px;
}

.forgotIcon {
  width: 50px;
  height: 50px;
}

.sidebar-layout {
  background: #ffffff;
  height: 88%;
  width: 100%;
  border-radius: 10px;
  max-width: 20%;
  padding: 10px 5px 10px 0;
  position: fixed;
  bottom: 15px;
  top: 70px;
}
.sidebar-layout .logOut {
  border-top: 1px solid #1890ff52;
}

.ml-27 {
  margin-left: 22% !important;
}

article {
  /* flex: 3 3; */
  margin: 0 18px;
  width: 70%;
}
.aside {
  flex: 1 1 5rem;
}

.w-21 {
  width: 21%;
}

.w-76 {
  width: 78%;
}
.w-30 {
  width: 30%;
}

.bg-cyano {
  background: #4bb7ba;
  color: #ffffff;
  border-radius: 8px;
  padding-top: 5px;
}

.bg-custom {
  border-radius: 8px;
  padding-top: 5px;
  border: 1px solid #cfdff9;
}

.bg-darkOrange {
  color: #ffffff;
  background: #ec5923;
  border-radius: 8px;
  padding-top: 5px;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

@media all and (max-width: 540px) {
  .bg-darkOrange {
    margin: 0;
  }
}

.bg-lightBlue {
  color: #ffffff;
  background: #4b9abb;
  border-radius: 8px;
  padding-top: 5px;
}

.m-16-main-content {
  width: 100%;
  margin-left: 16%;
}

.margin18 {
  margin-top: 60px !important;
}

.header {
  width: 100%;
  z-index: 110;
  background: #eaf2ff;
  max-width: 100%;
  min-height: 65px;
  padding: 5px 0;
}
header {
  top: 0;
  z-index: 100;
  width: 100%;
  left: 0;
  min-height: 65px;
}
.canvasjs-chart-credit {
  display: none;
}

/* @media  (min-width : 540px) and (max-width:1290px){
  .sidebar-position{
    width: 265px;
  }
}
@media  (min-width : 1290px) and (max-width:1450px){
  .sidebar-position{
    width: 297px;
  }
} */

.userIcon {
  width: 25px;
  height: 20px;
  margin-bottom: 9px;
}

.total-count {
  font-size: 22px;
  font-weight: 600;
  color: #003f63;
  /* font-family: 'ComptonBold' !important */
}

.font13 {
  font-size: 13px;
  font-family: "Compton Book";
}
.font14 {
  font-size: 14px;
  font-family: "Compton Book";
}
.font16 {
  font-size: 16px;
  font-family: "Compton Book";
}

.padd-8rem {
  padding: 0 0.8rem !important;
}

.transformY7 {
  transform: translateY(-7px);
}
.transformY6 {
  transform: translateY(6px);
}
.inactive-para {
  color: #44b2dc !important;
}

.padd-8rem.text-left .ant-progress .ant-progress-inner {
  width: 70px !important;
  height: 75px !important;
  font-size: 18px !important;
  margin-top: 5px;
}
.padd-8rem.text-left .ant-progress .ant-progress-inner .ant-progress-text {
  top: 50% !important;
  font-size: 0.9em !important;
}

.freshTotalCount {
  color: #4bb7ba;
  font-size: 18px;
}
.lawyercard {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 8.5px 0;
  background: transparent linear-gradient(180deg, #cfdff9 0%, #f2f2f2 100%) 0%
    0% no-repeat padding-box;
}

.transform15 {
  transform: translateY(15px);
}

.overall-perform {
  font-size: 21px;
  font-weight: 600;
  font-family: "Compton Book";
}

.transformY3 {
  transform: translateY(-3px);
}
.searchInput {
  border: none;
  width: 100px;
}
.searchInput:focus {
  border: none;
  outline: none;
}
.searchIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.searchInput::placeholder {
  color: #003f63;
  font-size: 14px;
  font-family: "ComptonLight";
  opacity: 1; /* Firefox */
}
table thead tr th {
  background: #0089cc;
  color: #ffffff;
  font-family: "Compton Book";
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  /* padding: 5px !important; */
}
table tbody tr td {
  color: #8a8a8a;
  font-family: "Compton Book";
  font-weight: normal;
  font-style: normal;
  padding: 5px 7px !important;
  font-size: 14px;
}
table tbody tr td img {
  width: 30px;
  height: 30px;
}
table tbody tr {
  border-bottom: 1px solid #dee2e6 !important;
}

.actionIcon {
  width: 20px;
  height: 15px;
  cursor: pointer;
  object-fit: contain;
}

/* toggle switch */

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 25px;
  margin: 0px 0 5px 0px;
  vertical-align: middle;
}

.switch input {
  display: none;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 6px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #0089cc;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #0089cc;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
  height: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.round {
  border-radius: 100%;
  background-color: #0089cc;
  width: 1%;
  height: 33px;
  padding: 5px 8px;
  margin: 0 10px;
  padding-right: 26px;
  cursor: pointer;
}

.round.active {
  background-color: #0089cc;
  color: white;
  cursor: pointer;
}

/* end toggle switch */

.pagination-detail {
  color: #8a8a8a;
  font-weight: normal;
  font-size: 13px;
}

button.close {
  color: red !important;
  opacity: 1 !important;
}

.modal-dialog.filterModal {
  width: 20%;
  position: absolute;
  right: 8%;
  top: 15%;
  height: 140px;
  margin: 0;
}

.back-button {
  height: 24px;
  padding: 0px 10px !important;
  border: 1px solid #8080804f !important;
}
.back-button:focus {
  height: 24px;
  padding: 0px 10px !important;
  border: 1px solid #8080804f !important;
  outline: none;
}

.bg-approve {
  color: #ffffff;
  background: #818181;
  border-radius: 8px;
  padding-top: 5px;
}

.bg-NewRequest {
  color: #ffffff;
  background: #0089cc;
  border-radius: 8px;
  padding-top: 5px;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.lb {
  transform: translateY(-2px);
}

.table_box {
  width: 100%;
  margin: 25px 20px 0 20px;
  padding: 25px 0 0;
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
}
.data-table {
  margin-top: 10px;
  background: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

table thead th {
  background: #0089cc;
  color: #ffffff;
  font-family: "Compton Book";
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
}
table tbody td {
  color: #8a8a8a;
  font-family: "Compton Book";
  font-weight: normal;
  font-style: normal;
}
table tbody td img {
  width: 30px;
  height: 30px;
}
table {
  /* display: block; */
  overflow-x: auto;
  white-space: nowrap;
}

.add_button {
  border-color: #97beff !important;
  color: #97beff !important;
  border-radius: 22.5px !important;
  font-family: "Compton Book";
}

input::placeholder {
  color: #8ba5af !important;
  font-family: "Compton Book";
}

select {
  color: #8ba5af !important;
}
/* option{
  color: #8BA5AF;
} */

.cameraIcon1 {
  width: 22px;
  height: 22px;
}

.cameraParent {
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 51px;
  position: absolute;
  top: 0;
  left: 51.5%;
  cursor: pointer;
  padding: 2px;
}

.fa-paperclip {
  color: #44b2dc;
}

.ph {
  color: #003f63;
  text-align: left;
  font-family: "Compton Book";
}
.pd {
  color: #8a8a8a;
  text-align: start;
  white-space: nowrap;
  font-family: "Compton Book";
}

img {
  cursor: pointer;
}

.tab-row {
  background: #e9e9e9;
  border-radius: 30px;
}

button.btn.tabBtn.btn-active {
  background: #0089cc;
  color: #fff;
  padding-left: 25px;
  border-radius: 30px;
  padding-right: 25px;
  padding-bottom: 9px;
}

button.btn.tabBtn {
  color: #c1c1c1;
  padding-left: 25px;
  border-radius: 30px;
  padding-right: 25px;
  padding-bottom: 9px;
}

.modal-forgot-password {
  top: 23% !important;
}
.logout-para-color {
  color: #acb5bd;
}

.img-WT-60 {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

hr {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  border: 0 !important;
  border-top: 1px solid #1890ff52 !important;
}

.star-img {
  width: 25px;
  height: 25px;
}

.sl-toggle-btn input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.sl-toggle-btn label {
  cursor: pointer;
  width: 40px;
  height: 22px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  margin-bottom: 0;
  margin-top: -20px;
  margin-left: 10px;
}

.sl-toggle-btn label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.sl-toggle-btn input:checked + label {
  background: #d62426;
}

.sl-toggle-btn input:checked + label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.sl-toggle-btn label:active:after {
  width: 20px;
}
.modal-header .close {
  padding: 0;
  margin: 0;
}
.sl-table-bx {
  padding: 10px 15px;
}
.centerModal {
  top: 50% !important;
  left: 50% !important;
  right: inherit !important;
  transform: translate(-50%, -50%) !important;
  max-width: 445px;
  width: 100% !important;
  margin: 0;
  height: auto !important;
}
.centerModal .modal-header {
  border-bottom: 0;
  padding: 5px;
}
.table-sm td,
.table-sm th {
  vertical-align: middle !important;
  /* font-family: 'ComptonLight'; */
}
.sl-icon {
  display: inline-block;
}
.sl-filter-modal {
  height: auto !important;
  margin: 0 !important;
  width: 28% !important;
}
.sl-bx {
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  margin-left: 1.5rem;
  padding: 15px;
  margin-bottom: 15px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  margin: 0 auto;
  width: 100%;
  z-index: 9999;
  background-color: #00000091;
  top: 0;
  left: 0;
}
.spinner-border.text-success {
  color: #0089cc !important;
}

.sl-bx {
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgb(0 0 0 / 10%);
  background-color: #ffffff;
  margin-left: 1.5rem;
  padding: 15px;
  margin-bottom: 15px;
}
.searchFilter {
  border-bottom: 1px solid #eaf2ff;
}
.other .flex-item {
  border-radius: 10px;
  margin: 15px;
  margin-left: 0;
  padding: 12px 15px;
  text-align: center;
}
.other .flex-item.bl-bg {
  background: #1bafd0 0% 0% no-repeat padding-box;
}
.other .flex-item.or-bg {
  background: #fd636b 0% 0% no-repeat padding-box;
}
.other .flex-item.yl-bg {
  background: #ffb902 0% 0% no-repeat padding-box;
}
.other .flex-item.gn-bg {
  background: #3be8b0 0% 0% no-repeat padding-box;
}
.other .flex-item.bbl-bg {
  background: #6967ce 0% 0% no-repeat padding-box;
}

.other .flex-item h4 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
  padding: 5px 0;
}
.other .flex-item p {
  font-size: 14px;
  margin-bottom: 0;
  color: #fff;
  font-weight: 100;
}
.other .flex-item.bl-bg p,
.other .flex-item.or-bg p,
.other .flex-item.yl-bg p {
  font-family: "ComptonLight";
}
.other .flex-item .sl-icon svg .a {
  fill: #fff;
}
.other .sl-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
}
.other .sl-icon svg {
  width: 25px;
  height: 25px;
}
.other .nav-tabs {
  border: none;
  margin-bottom: 15px;
  background: #e9e9e9;
  display: inline-flex;
  border-radius: 30px;
}
.other .nav-tabs li a {
  border: none;
  border-radius: 30px;
  margin-bottom: 0;
  font-size: 14px;
  color: #c1c1c1 !important;
}
.other .nav-tabs li a.active {
  border: none;
  background: #0089cc 0% 0% no-repeat padding-box;
  color: #fff !important;
}
.otherTabs {
  text-align: left;
}
.other .table thead th {
  font-size: 16px;
  padding: 7px 15px;
  border-bottom: none;
}
.other .action a {
  padding: 0 5px;
}
.other .action a span {
  width: 20px;
  height: 20px;
}
.other .action a span svg {
  width: 18px;
  height: 18px;
}
.other .action a span.sl-icon img {
  max-height: 18px;
  width: 18px;
}
.other .tick svg {
  fill: #32bea6;
}
.other .cancel svg {
  fill: red;
}
.other .pagination {
  margin-bottom: 0;
}
.sl-bx.mb-3 {
  margin-bottom: 15px;
}
.slHeading {
  border-bottom: 1px solid #eaf2ff;
  padding-bottom: 8px;
  margin-bottom: 0;
  padding-left: 15px;
}
.newDetails label {
  font-size: 14px;
  color: #003f63;
  margin-bottom: 0;
}
.newRequest .flex-item {
  flex: 0 0 25%;
  max-width: 25%;
  width: 100%;
  margin: 0;
  text-align: left;
}
.newDetails p {
  font-size: 14px;
  color: #8a8a8a !important;
}
.newRequest .action-btns {
  margin-top: 20px;
}
.action-btns a {
  display: inline-block;
  background: #e9e9e9;
  padding: 7px;
  border-radius: 8px;
  margin-right: 10px;
  text-align: center;
}
.action-btns a span svg,
.action-btns a span img {
  width: 20px !important;
  height: 20px !important;
}
.subTabs .sl-bx {
  margin-left: 0;
  box-shadow: none;
}
.subTabs .nav-tabs {
  background: transparent;
}
.subTabs .otherTabs .nav-tabs li.nav-item a.nav-link {
  border-bottom: 4px solid #c1c1c5 !important;
  border-radius: 0;
}
.subTabs .otherTabs .nav-tabs li.nav-item a.active {
  background: transparent;
  border-bottom-color: #0089cc !important;
  color: #0089cc !important;
}
.subTabs .sl-bx {
  padding: 0;
}
.approvedTabs {
  margin-top: 10px;
}
.subTabsBooked .flex-item {
  flex: 0 0 50%;
  max-width: 50%;
}
.subTabsBooked .flex-item {
  padding: 0;
}
.filterPopup {
  text-align: right !important;
}
.filterPopup .sl-icon svg {
  width: 20px;
  height: 20px;
}
.appointModal {
  top: 15% !important;
  width: 25% !important;
  right: 5% !important;
}

.appointModal input {
  font-size: 13px;
}
.box.lawyer_photo {
  width: 50px;
  height: 50px;
  overflow: hidden;
  background: #f1f1f1;
  margin-right: 5px;
  border-radius: 5px;
}
.lawyer-detail .sl-icon svg .a,
.lawyer-detail .sl-icon svg {
  fill: #aaa !important;
}
.lawyer-detail .sl-icon svg,
.lawyer-detail .sl-icon {
  width: 15px !important;
  height: 15px !important;
}
.newDetails.msg {
  padding-left: 15px;
}
.lawyer-ratings {
  border-top: 1px solid #eaf2ff;
}
.lawyer-ratings .slHeading {
  border-bottom: 0;
}
.relevantDocs .newDetails p {
  font-size: 12px;
}
.relevantDocs .flex-item {
  flex: 0 auto;
  max-width: inherit;
  width: auto;
}
.ratings {
  display: inline-block;
  margin-left: 10px;
}
.ratings .star svg {
  fill: #ffdc64 !important;
}
.lawyer-detail.relevantDocs {
  padding-bottom: 15px;
}
.lawyer-ratings {
  padding-bottom: 0 !important;
}
.lawyer-ratings .slHeading {
  padding: 10px;
  padding-bottom: 0;
}
.newDetails p.statusComplete {
  color: #399739 !important;
}
.filterbx label {
  color: #003f63;
}
.statusList label {
  color: #787e81;
  padding-right: 10px;
}
.statusList input {
  margin-right: 10px;
}
.filterbx {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 15px;
  margin-bottom: 10px;
}
.filterbx.bb0 {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.stepsForm label {
  margin-top: -15px;
  text-align: center;
  height: 28px !important;
  width: 28px !important;
}
.stepsForm label label {
  height: 21px !important;
  width: 22px !important;
}
.backBtn {
  margin-bottom: 10px;
}
.profileSL {
  text-align: center;
}

.paginationActive {
  padding: 2px 10px;
  width: auto;
  height: auto;
  background: #0089cc;
}
.paginationActive a {
  color: #fff !important;
}

.form-control.paginateSearch {
  width: 75px;
  height: 34px;
  padding: 5px 15px 7px;
  border-radius: 8px;
  background-color: #f7f7f7;
  border: none;
}

.pagesPagination {
  padding: 2px 10px;
  margin-left: 1rem !important;
  width: 30px;
  height: 30px;
}
.pagination {
  align-items: end;
}

.pageClassName {
  padding: 2px 10px;
  margin: 0 7px;
  /* width: 30px;
  height: 30px; */
  border: 1px solid transparent;
}

.pagination {
  align-items: end;
}

.pagination li.disabled a {
  cursor: default;
}
.pagination li.disabled .nextLinkClassName::before {
  border-left-color: #c5cac7;
}
.ab .pagination .nextLinkClassName::before {
  transform: scaleX(-1);
}
.ab .pagination .previousLinkClassName::before {
  transform: scaleX(-1) !important;
  right: 10px;
}

.commentBox .pagination {
  justify-content: center;
}
.ab .commentBox .pagination {
  padding-right: 0;
}
.pagination {
  margin-bottom: 0;
}

.client-profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.no-client-profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: 1px solid #ccc;
}
.earnYear .cn-item {
  border-right: 1px solid #ccc;
  padding: 0 30px;
  text-align: center;
}
.earnYear .cn-item:last-child {
  border-right: none;
}

.earnYear .cn-item p {
  font-size: 14px;
  color: #8a8a8a;
  margin-bottom: 0;
}
.earnYear .cn-item h4 {
  font-size: 18px;
  color: #003f63;
  font-weight: 600;
  margin-bottom: 0;
}
.cn-chart svg text[Attributes] {
  fill: #8a8a8a;
}
.cn-chart {
  background: #eeeeee;
}
.sl-jas {
  justify-content: space-between;
}
.filterModal .close {
  padding: 0;
  margin: 0;
}
.yearlyFilter label {
  display: flex;
  justify-content: space-between;
  color: #787e81;
  font-weight: 400;
}

.py-3.filter-btns,
.filter-btns {
  padding-bottom: 0 !important;
}
.sl-filter {
  width: 100%;
  flex: 0 0 25%;
  max-width: 25%;
  justify-content: space-between;
}
.sl-filter .searchFilter {
  width: 85%;
  border-bottom: none;
}
.sl-filter .searchFilter input {
  width: 80%;
  margin-left: 5px;
}
.selectMonth {
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.selectMonth select {
  width: 15%;
  border: none;
}
.selectMonth label {
  margin-bottom: 0;
  margin-right: 5px;
}
.filterModal.paymentFilter {
  width: 25%;
}
.paymentFilter input {
  font-size: 14px;
  padding: 5px 0 5px 10px;
}

.bl-lt-bg {
  background: #4bb7ba;
}
.org-bg {
  background: #ec5923;
}
.follow-bg {
  background: #4b9abb;
}
.appoint .flex-item {
  display: flex;
  align-items: center;
}
.appoint .flex-item h4 {
  padding: 0 5px;
}
.appoint .flex-item p {
  font-size: 14px;
  font-family: "Compton Book";
}
.appoint .table thead th {
  font-size: 14px;
  padding: 7px;
}
.appoint {
  margin-bottom: 20px;
}
.flexwb {
  justify-content: space-between;
}
.openDoc {
  border: 1px solid #97beff;
  border-radius: 30px;
  width: 100px;
  text-align: center;
  display: block;
  text-transform: capitalize;
  font-family: "Compton Book";
  color: #97beff !important;
  font-size: 14px;
  padding: 3px;
}
.bb1 {
  border-bottom: 1px solid #eaf2ff;
}
.bb1 .slHeading {
  border-bottom: none;
}
.sl-back-btn {
  font-size: 14px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  display: inline-block;
  line-height: 24px;
  padding: 0px 10px;
  margin-bottom: 8px;
  text-decoration: none !important;
  font-size: 14px;
  text-align: center;
}
.clientProfile {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #dee2e6;
}
.clientProfile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.input-range__slider {
  background: #003f63 !important;
  border: 2px solid #fff !important;
  box-shadow: 0 0 2px #ccc;
}
.input-range__track--active {
  background: #0089cc !important;
}
.input-range__label--min,
.input-range__label--max {
  display: none;
}
.input-range__label--value {
  top: 10px !important;
}
.rangeInput {
  margin-bottom: 20px;
}
.sl-btns {
  padding-top: 25px;
}
.Page.navigation {
  margin-bottom: 15px;
}
.addNewLawyer {
  padding: 0 80px;
}
.addNewLawyer form .col-md-6,
.addNewLawyer form .col-md-12 {
  padding-left: 10px;
  padding-right: 10px;
}
.addNewLawyer form {
  margin-top: 15px;
}
.nextBtn {
  text-align: center;
}
.addNewLawyer p {
  text-align: center;
  margin-bottom: 20px;
}
.addNewLawyer label {
  margin-bottom: 0;
}
.attechFile {
  position: relative;
}
.attechFile input {
  display: none;
}
.attechFile {
  border: 1px solid #ccc;
  display: block;
  height: 38px;
  padding: 6px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.days .day {
  background: #eeeeee;
  width: 60px;
  height: 60px;
  flex: 0 0 13%;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  line-height: 40px;
}
.days .day p {
  margin-bottom: 0;
  color: #aaa5a5;
  font-weight: 400;
}
.flex.days {
  justify-content: space-between;
  align-items: center;
}
.day.active {
  background: #44b2dc;
  color: #fff;
}
.day.active p {
  color: #fff;
}
.startEndTime {
  justify-content: space-between;
  margin-top: 30px;
}
.startEndTime .time {
  flex: 0 0 49%;
  max-width: 49%;
  width: 100%;
}
.timebox {
  margin-top: 20px;
}
.timebox h3 {
  font-size: 20px;
  color: #505050;
  margin-bottom: 5px;
}
.timebox button {
  background: transparent;
  border: 1px solid #d9d9d9;
  padding: 7px 10px;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 14px;
}
.timebox button.selected {
  border-color: #a9def9;
}
.sl-modal-btns {
  margin: 25px 0 5px 0;
  text-align: center;
}
.sl-modal-btns button {
  background: #44b2dc;
  font-size: 20px;
  border: none;
  font-weight: 100;
  padding: 8px 40px;
}
.sl-modal-btns button:hover,
.sl-modal-btns button:focus {
  background: #44b2dc;
}
.action a span svg {
  width: 20px;
  height: 20px;
  fill: #9a9a9a;
}
.action a {
  display: inline-block;
  padding: 0 3px;
}
.action a span {
  width: 20px;
  height: 20px;
}
.data-table .tick svg {
  fill: #32bea6;
}
.data-table .cancel svg {
  fill: red;
}
.sidebar-layout .sl-icon {
  display: inline-block;
}
.sidebar-layout .sl-icon svg {
  width: 18px;
  height: 18px;
  fill: #44b2dc;
  vertical-align: text-bottom;
}

.scroll {
  height: 100vh;
}
.sl-logo {
  text-align: center;
}
.sl-login .Rectangle {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}
.error {
  text-align: right;
}
.pagination {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-flex !important;
}
.pagination li {
  padding: 0;
  margin: 0;
  border-right: 1px solid #ccc;
  text-transform: capitalize;
}
.pagination li a {
  padding: 8px 15px;
  display: inline-block;
}

.pagination li:last-child {
  border-right: none;
}
.pagination li.disabled a {
  color: #8a8a8a !important;
}
.paginationBx {
  padding: 12px;
  margin-left: 22px;
  margin-top: 30px;
}
.pagiWapper {
  text-align: right;
}
.react-datepicker__input-container input {
  padding-left: 30px;
}
.react-datepicker__input-container {
  position: relative;
}
.react-datepicker__input-container::before {
  content: "";
  position: absolute;
  background: url(./img/calendar.svg) no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  top: 8px;
  left: 8px;
}
.react-datepicker__close-icon::after {
  background: #0089cc;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
  order: -1;
}
.statusList label {
  width: 50%;
}
.statusList label input {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
.statusList label span {
  font-family: "Compton Book";
}
.cort-icon svg {
  width: 16px;
  height: 16px;
  fill: #aaa;
}
.add-more {
  display: inline-block;
  text-decoration: underline !important;
}
.sl-deleteIc svg {
  fill: #8a8a8a;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #0089cc !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0089cc !important;
}
.sidebar-layout .sl-icon svg,
.sidebar-layout .sl-icon svg .a {
  fill: #44b2dc;
}
.sidebar-label.active .sl-icon svg,
.sidebar-label.active .sl-icon svg .a {
  fill: #003f63;
}

.modal-header .close {
  padding: 0;
  margin: 0;
}
.modal-header {
  padding: 10px !important;
}
.action .view {
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  height: auto;
  background: #fff;
  color: #0089cc;
  padding: 3px 15px;
  margin: 5px;
  font-weight: 400;
  font-size: 12px;
  margin-left: 0;
  display: inline-block;
}
.billTo {
  justify-content: space-between;
  padding-top: 20px;
  /* border-bottom: 1px solid #DDEEFB; */
  padding-bottom: 10px;
}
.billTo h6,
.viewInvoice h6 {
  font-family: "Compton Medium";
}
.viewInvoice {
  padding-bottom: 10px;
}
.billName p {
  margin-bottom: 0;
  color: #8a8a8a;
  font-size: 16px;
  font-family: "Compton Book";
  line-height: 20px;
}
.invoiceDate p {
  margin-bottom: 0;
  font-size: 14px;
  font-family: "Compton Book";
  line-height: 20px;
}
.invoiceDate span {
  color: #8a8a8a;
}

.viewInvoice .status {
  color: #65b852;
}
.relevantDoc {
  padding-bottom: 5px;
}
.invoice .view {
  padding: 3px 15px !important;
}

.subAdmin .action .sl-toggle-btn label {
  margin-top: -20px;
  margin-left: 0;
}
.subAdmin .action .sl-icon svg {
  fill: gray;
}
.statusBx label span {
  font-family: "Compton Book";
}
.statusBx label {
  width: 50%;
  color: #787e81;
}
.statusBx label input {
  vertical-align: middle;
  margin-right: 8px;
  font-size: 16px;
}
.view-btn {
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  height: auto;
  background: #fff;
  color: #0089cc;
  padding: 3px 15px;
  margin: 5px;
  font-weight: 400;
  font-size: 12px;
  margin-left: 0;
  display: inline-block;
}
.payInfoBox p {
  margin-bottom: 0;
}
.payInfoBox p span {
  display: inline-block;
  min-width: 100px;
}
.view_payment .cn-btns button {
  background: #0089cc 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 6px #00000029;
  border-radius: 6px;
  display: inline-block;
  border: none;
  width: 153px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "ComptonLight";
}
.view_payment .cn-btns {
  text-align: right;
  margin-top: 20px;
}
.view_Pay {
  position: relative;
}
.view_Pay .selectMonth {
  position: absolute;
  right: 10px;
  width: 230px;
  display: flex;
  justify-content: space-between;
}
.view_Pay .selectMonth select {
  width: 50%;
}
.spinner-border {
  color: #0189cc !important;
}
.tab-row {
  min-height: 41px !important;
}
.sl-main-box {
  background: #fff;
  border-radius: 0.625rem;
  border: 1px solid #CCCCCC;
  margin-bottom: 3.938rem;
}
@media (max-width : 1024px) and (min-width:768px){
  
  .calender-order{
    order: -1;
  }

 


}

@media (min-width: 1260px) and (max-width: 1280px) {
  .sidebar-layout {
    max-width: 20%;
  }
}
@media screen and (min-width: 1920px) {
  .other .action a span svg {
    width: 20px;
    height: 20px;
  }
  .sidebar-layout {
    max-width: 20%;
  }
  .sidebar-padd {
    font-size: 20px;
    padding: 0px 25px !important;
  }
  .sidebar-label {
    height: 47px;
    line-height: 47px;
  }
  .sidebar-layout .sl-icon svg {
    vertical-align: middle;
    width: 24px;
    height: 24px;
  }
  .sidebar-layout .sl-icon {
    margin-right: 10px;
  }
}

.sl-btns button {
  background-color: rgb(68, 178, 220);
  width: 180px;
  height: 50px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  font-size: 18px;
  color: #fff;
  font-family: "Compton Book";
}
.sl-btns .sl-save {
  background: #003f63;
}

.sidebar_toggle {
  display: none;
}
.upload_bx {
  width: 100%;
}
.upload_bx p {
  display: inline-block;
  margin-left: 10px !important;
}
.status_grid .active_bx {
  width: 100%;
  margin: 0 !important;
  padding: 10px 15px;
}
.status_grid .active_bx label label {
  display: block;
}
.status_grid {
  margin-left: -5px;
  margin-right: -5px;
}
.status_grid .col-md-3 {
  padding-left: 5px;
  padding-right: 5px;
}
.sidebar_toggle svg .a {
  fill: #0189cc !important;
}
.phone_box .countryBx {
  padding-right: 0;
}
.phone_box .phoneInput {
  padding-left: 0;
  margin-left: -1px;
}
.phone_box .phoneInput .form-control {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.phone_box .countryBx .form-control {
  border-radius: 0.25rem 0 0 0.25rem;
}
.photoName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 20px;
}
.appointSlot .flex.time-list .flexbx span {
  cursor: pointer;
}
.multiple_add {
  padding: 0 10px;
}
.service_box {
  margin-bottom: 15px;
}
.multiple_add .row .col-md-12:last-child .service_box {
  margin-bottom: 0;
}
.time input {
  padding-left: 0;
}
.ck-content.ck-editor__editable {
  min-height: 150px;
}
.phone_bx .phoneNumber .form-control {
  border-radius: 0 0.25rem 0.25rem 0;
  margin-left: -1px;
}
.phone_bx #countryCode {
  border-radius: 0.25rem 0 0 0.25rem;
}
.form-control:focus {
  border-color: #0085c8;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .w-76.ml-27 {
    width: 100%;
    margin-left: 0 !important;
  }
  .swiftLaw {
    padding: 0 !important;
  }
  .sidebar_toggle {
    display: block;
  }
  .sidebar_toggle {
    position: fixed;
    left: 15px;
    transition: 0.7s;
    z-index: 111;
    top: 18px;
  }
  .header .header_item:first-child {
    width: 85%;
    text-align: center;
    padding-left: 10%;
  }
  .header .flex {
    flex-wrap: inherit;
  }
  .profile span {
    display: none;
  }
  .close-sidebar .sidebar-layout {
    left: -70%;
    padding-top: 70px;
    top: 0;
  }
  .open-sidebar .sidebar-layout {
    left: 0%;
    transition: 0.6s;
    padding-top: 70px;
    z-index: 100;
    top: 0;
    max-width: 35%;
    height: 100%;
    border-radius: 0;
  }
  .open-sidebar .overlay {
    background: rgba(90, 85, 85, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
  }
  .open-sidebar .sidebar_toggle svg {
    width: 22px;
    height: 22px;
    fill: #0189cc;
  }
  article {
    width: 100%;
  }
  .flex-fill .d-flex {
    flex-wrap: wrap;
  }
  .w-30 {
    width: 100%;
    margin: 0 15px;
    margin-top: 15px;
  }
  .sl-main-box {
    margin: 0 15px;
  }
  .active_status_box .flex-fill.bg-custom.ml-4 {
    margin-left: 0 !important;
    margin: 0 2px !important;
  }
  .total_lawyer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    align-items: center;
  }
  .total_lawyer .lawyercard {
    flex: 0 0 49%;
    max-width: 48%;
    width: 100%;
    margin: 5px 5px !important;
  }
  .flex-fill .Rectangle.ml-4 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .modal-dialog.filterModal {
    position: static;
    margin: 0 auto;
    width: 40%;
    transform: inherit !important;
  }
  .mm-auto {
    margin-left: 15px !important;
    margin-right: 15px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .other_page .align-items-end label .tabBtn {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sl-bx {
    margin-left: 15px;
    margin-right: 15px;
  }
  .other-flex .flex-fill {
    margin-bottom: 10px;
    flex: 0 0 23.1%;
    padding-bottom: 5px !important;
  }
  .other-flex .flex-fill label {
    display: block;
  }
  .main {
    height: auto;
    min-height: 100vh;
  }
  .addNewService {
    text-align: right;
  }
  .appoint .total__apt .flex-item {
    margin: 15px 5px !important;
    margin-bottom: 0 !important;
  }
  .newRequest .flex-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sl-filter-modal {
    height: auto !important;
    margin: 0 auto !important;
    width: 100% !important;
  }
  input[type="time"] {
    padding-left: 0;
  }
  .viewInvoice {
    padding-right: 30px;
  }
  .viewInvoice p.ph {
    margin-bottom: 0;
  }
  .law_det_bx {
    padding-top: 5px;
  }
  .mt-5.mb-2 {
    margin-top: 15px !important ;
  }
  .viewInvoice .Rectangle.mm-auto {
    padding: 0 20px !important;
  }
  .add_submin {
    text-align: right;
  }
  .new-admin-from {
    padding: 20px 0;
  }
  .lowyer_info {
    border-radius: 4px;
  }
  .appointment-list {
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .legalDocs .agencyType .sl-table-bx {
    padding-left: 0;
    padding-right: 0;
  }
  .appointSlot .sl-bx {
    padding: 10px 15px;
  }
  .acStatus {
    border-radius: 4px;
  }
  .active_bx {
    border-radius: 4px;
  }
  .active_bx label {
    margin-bottom: 0;
  }
  .lowyer_info {
    border-radius: 4px;
  }
  .modal-dialog {
    position: static !important;
    width: 95% !important;
    transform: inherit !important;
  }
  .active_status_box .flex-fill {
    border-radius: 4px;
  }
  .invoice .total__apt .flex-item {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .invoice .search_bx {
    padding: 5px 0 !important;
  }
  .payInfoBox p span {
    min-width: 115px;
  }
  .payInfoBox p {
    display: flex;
    align-items: center;
    padding: 4px 0;
  }
  .view_Pay .selectMonth {
    position: static;
    width: 100%;
  }
  .selectMonth select {
    width: 35%;
  }
  .payments .sl-table-bx .sl-item {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .payments .sl-table-bx .sl-item h6 {
    padding-bottom: 15px !important;
  }
  .earnings .sl-table-bx {
    padding: 10px 0;
  }
  .earnYear .cn-item {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    text-align: left;
    padding: 5px 0;
    border-right: none;
    border-bottom: 1px solid #ccc;
  }
  .earnYear .cn-item:last-child {
    border-bottom: none;
  }
  .multiple_add {
    padding: 0;
  }
  .sl-login .m-auto {
    width: 100%;
  }
  .sl-login {
    padding: 0 15px;
  }
  .addNewLawyer form .col-md-6,
  .addNewLawyer form .col-md-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .add-new-Lawyer .sl-main-box {
    margin-bottom: 15px;
  }
  input#chooseService {
    display: none;
  }
  .fresh_appt .sl-main-box {
    margin-bottom: 15px;
  }
  .fresh_appt .fm-bx {
    margin-top: 10px;
  }
  .fresh_appt .fm-bx p {
    margin-bottom: 0;
  }
  .fresh_appt .ml-5 {
    margin-left: 0 !important;
  }
  .fresh_appt .pl-4 {
    padding-left: 0 !important;
  }
  .d-flex.justify-content-end {
    justify-content: center !important;
    padding: 0px !important;
  }
  .back-button {
    height: auto;
  }
  .fm_bx {
    margin-top: 10px;
  }
  .fm_bx label {
    margin-bottom: 0;
  }
  .mwa {
    width: auto !important;
    margin-right: 0 !important;
  }
  .rm15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .pagination_box {
    padding-bottom: 10px;
  }
  .pagiWapper {
    text-align: center;
  }
  .table_wapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .status_grid .bg-lightBlue {
    margin-top: 10px !important;
  }

  .search_field {
    padding: 6px 0;
  }
  .Rectangle {
    border-radius: 4px;
  }
  .sidebar-layout {
    max-width: 70%;
    padding: 10px 15px;
  }
  .beforeLogin {
    padding: 45px 15px 60px 15px !important;
  }
  .table-sm td,
  .table-sm th {
    padding: 0.3rem 20px;
  }
  .tab-row {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .ratings .sl-icon {
    display: inline-block;
    margin-right: 20px;
  }

  .ratings {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }
  .relevantDocs .sl-icon svg {
    width: 25px !important;
    height: 25px !important;
    margin-bottom: 15px;
  }
  .newDetails.msg {
    padding-left: 0;
    padding-top: 10px;
  }
  .lawyer-detail {
    margin-top: 20px;
  }
  .filterPopup {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100%;
  }
  .subTabsBooked .flex-item {
    flex: 0 0 100%;
    max-width: 100% !important;
    width: 100%;
  }
  .subTabsBooked .nav-tabs {
    border-radius: 0;
    margin-bottom: 5px;
  }
  .subTabsBooked .sl-icon.filter {
    margin-bottom: 10px;
  }
  .slHeading {
    padding-left: 0;
  }
  .action-btns a {
    padding: 5px;
  }
  .newRequest .flex-item {
    max-width: 50%;
    flex: 0 0 50%;
    margin-bottom: 0 !important;
    padding-bottom: 0;
    padding-left: 0;
  }
  .other .flex-item p {
    font-size: 14px;
    line-height: 18px;
  }
  .pagination li a {
    padding: 4px 8px;
    font-size: 14px;
  }
  .other .nav-tabs li a {
    padding: 5px 15px;
  }
  .other .nav-tabs {
    flex-wrap: inherit;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
  }
  .other-flex {
    justify-content: space-between;
  }
  .other .flex-item {
    flex: 0 0 48%;
    margin: 7px 0;
    border-radius: 5px;
  }
  .ViewModal .btn-sm {
    width: auto !important;
  }
  .law-save-btn .add_button {
    width: 100% !important;
  }
  .mmb15 {
    margin-bottom: 15px;
  }
  .sidebar_toggle {
    display: block;
  }
  .mml0 {
    margin-left: 0 !important;
  }
  .lawyer-details-box {
    padding-bottom: 20px;
  }
  .law-pInfo {
    padding: 5px 0;
  }
  .law-pInfo p {
    margin-bottom: 0;
  }
  .back-btn-wapper .btn-sm {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .lawyer_details .Rectangle.ml-3 {
    margin-left: 0 !important;
  }
  .stepsForm p {
    margin-left: -20px;
    padding-top: 15px;
    white-space: nowrap;
  }
  .profileSL {
    margin-top: 20px;
  }
  .mpl0 {
    padding-left: 0 !important;
  }
  .add-new-Lawyer .Rectangle.ml-3 {
    margin-left: 0 !important;
  }
  .add-new-Lawyer .backBtn {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .addNewLawyer {
    padding: 0px;
  }
  .sl_tabs_menu .tab-row {
    white-space: nowrap;
    overflow-x: auto;
  }
  .pagination-detail {
    display: block;
    text-align: center;
  }
  .lawyer_add_new {
    order: -1;
    padding-right: 0;
  }
  .lawyer_add_new img {
    width: 20px;
  }
  .lawyer_add_new .add_button {
    padding: 4px 6px;
  }

  .lawyer_add_new .add_button {
    margin: 0 !important;
    margin-bottom: 10px !important;
  }

  .sl_tabsBx {
    padding-top: 15px !important;
  }
  .mp10 {
    padding-bottom: 10px !important;
  }
  .appointModal {
    top: 10% !important;
    width: 95% !important;
  }
  .filter_ic {
    order: -1;
    padding-bottom: 15px;
    margin-top: -20px !important;
  }
  .tab-row .tabBtn {
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-size: 14px !important;
  }
  .transformY7 {
    line-height: 20px !important;
  }
  .mrow15 {
    margin-right: -15px !important;
    margin-left: -15px !important ;
  }
  .py-3.px-4 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .paginationBx {
    margin-left: 0;
    margin-top: 15px;
    padding-bottom: 10px !important;
  }
  /* .modal-dialog.filterModal{
    width: 70%;
    right: inherit;
    left: 50%;
    transform: translateX(-50%) !important;
    top: 20%;
  } */
  .searchInput {
    width: 87%;
  }
  .cm-box {
    margin-bottom: 15px;
  }
  .Rectangle.ml-4 {
    margin-left: 0 !important;
  }

  .otherUser__box,
  .daily_income {
    margin-left: 0 !important;
  }
  .daily_income .px-5 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .lawyer__box {
    margin-top: 15px;
  }
  .font13 {
    font-size: 14px;
    line-height: 26px;
  }
  .sl-bx {
    margin-left: 0;
  }
  .client_box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .mplpr {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .client_box .flex-fill {
    margin: 10px 5px !important;
  }
  .mp0 {
    padding: 0 !important;
  }
  .flex-fill .d-flex {
    flex-wrap: wrap;
  }

  article {
    width: 100%;
    margin: 0;
  }
  .swiftLaw {
    padding: 0 15px !important;
    overflow-wrap: anywhere;
  }
  .w-76.ml-27 {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .w-30 {
    width: 100% !important;
  }

  .sidebar-layout {
    height: 100%;
    border-radius: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 100;
    box-shadow: 7px 0 60px rgb(0 0 0 / 8%);
    transition: 0.6s;
  }
  .close-sidebar .sidebar-layout {
    left: -70%;
    padding-top: 70px;
  }
  .open-sidebar .sidebar-layout {
    left: 0%;
    transition: 0.6s;
    padding-top: 70px;
  }

  .open-sidebar .sidebar_toggle svg {
    width: 22px;
    height: 22px;
    fill: #0189cc;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e1e1e1;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height:16px;
    width: 16px;
    left:2px;
    bottom:2px;
    background-color: #0089cc;
    transition: 0.4s;
  }
  .open-sidebar .overlay {
    background: rgba(90, 85, 85, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
  }
  .sidebar_toggle {
    position: fixed;
    left: 15px;
    transition: 0.7s;
    z-index: 111;
    top: 18px;
  }
  .lawyerMgtBox.py-3.ml-4 {
    padding: 15px !important;
  }
  .lowyer_info {
    flex: 0 0 100% !important;
    margin: 0;
    padding: 10px !important;
    margin-bottom: 15px;
  }
  .lowyer_info label {
    margin-bottom: 0;
  }
  .lawyerMgtBox .d-flex {
    align-items: center;
  }
  .lawyerMgtBox .transformY6 {
    transform: translateY(0px);
    margin-bottom: 0;
  }
  .lawyerMgtBox .actionIcon {
    vertical-align: text-top;
  }
  .sl-login {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .sl-login .m-auto {
    margin: 0 !important;
  }
  .container,
  .container-sm {
    max-width: 100%;
  }
  .logo_image {
    margin-top: 0;
  }
  .sl_tabsBx {
    margin-top: 10px !important;
  }
  .days .day {
    width: 30px;
    height: 50px;
    line-height: 30px;
  }
  .setAvail .sl-switch {
    position: absolute;
    right: 0;
    top: -90px;
    margin-top: 0 !important;
  }
  .data-table {
    padding-left: 0;
    padding-right: 0;
  }
  .other_page .other_Box {
    padding: 15px !important;
  }
  .other-flex {
    margin: 0 -5px;
  }
  .other-flex .flex-fill {
    max-width: 47%;
    width: 100%;
    margin: 0 5px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  .other-flex .flex-fill label label {
    display: block;
  }
  .mm-auto {
    margin: 0 !important;
    margin-top: 10px !important;
    padding: 15px !important;
  }
  .main {
    height: 100%;
  }
  .searvice .sersearch {
    order: 1;
  }
  .sersearch {
    order: 1;
  }
  .acStatus {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 5px 0;
  }
  .addNewService {
    text-align: right;
  }
  .sersearch .searchInput {
    padding-bottom: 8px;
    padding-right: 10px;
    margin-left: 5px;
  }
  .services .action a:last-child {
    min-width: 50px;
  }
  .modal-dialog {
    margin: 0.5rem auto !important;
    width: 95%;
  }
  .addNewServiceModal {
    position: static !important;
    transform: inherit !important;
  }
  .swal2-title {
    font-size: 18px;
  }
  input[type="time"] {
    padding-left: 0;
  }
  .viewInvoice p {
    margin-bottom: 0;
  }
  .viewInvoice .billTo .sl-item {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .viewInvoice .billTo {
    padding-top: 0;
    flex-wrap: wrap;
  }
  .viewInvoice .billName {
    margin-bottom: 10px;
  }
  .viewInvoice .row.m-0 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .viewInvoice .Rectangle.mm-auto {
    margin-top: 0 !important;
  }
  .viewInvoice .law_det_bx .ml-5 {
    margin-left: 0 !important;
  }
  .viewInvoice .law_det_bx {
    margin-top: 10px;
  }
  .viewInvoice .row.ml-2 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .billTo h6,
  .viewInvoice h6 {
    padding-left: 0 !important;
  }
  .viewInvoice .relevantDoc .d-flex {
    flex-wrap: initial;
    margin-top: 15px;
  }
  .viewInvoice .relevantDoc .d-flex .pd {
    white-space: inherit;
  }
  .appoint .search_bx input {
    padding: 5px 0;
    margin-left: 5px;
  }
  .appoint .total__apt .flex-item {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .appoint .action a:last-child {
    min-width: 50px;
  }

  .phone_bx .phoneNumber input {
    border-radius: 0 0.25rem 0.25rem 0 !important;
    margin-left: -1px;
  }
  #countryCode {
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
}

@media (max-width: 375px) {
  .close-sidebar .sidebar-layout {
    left: -90%;
  }
  .days .day {
    width: 30px;
    height: 35px;
    line-height: 25px;
    font-size: 12px;
    border-radius: 4px;
    padding: 5px;
  }
  .newRequest .flex {
    flex-wrap: wrap;
  }
  .newRequest .flex .flex-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .other-flex .flex-fill {
    max-width: 100%;
  }
}

@media (max-width: 360px) {
  .stepsForm hr {
    width: 70px !important;
  }
}
.capitalize {
  text-transform: capitalize;
}
.law-pInfo p {
  white-space: normal;
  word-break: break-all;
}
.sl-switch .slider.round {
  border-radius: 34px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e1e1e1;
  transition: 0.4s;
}
.DayPicker {
  display: inline-block;
  font-size: 1rem;
}
.DayPicker{
  width: 100% !important;
}
.DayPicker-Month{
  margin:1rem 0 !important;
  width: 100%;
}

.DayPicker-Day{
  border-radius: 8px !important;
  color: #505050 !important;
}
div.DayPicker-Day.DayPicker-Day--today{
  font-weight:400;
}
/* .DayPicker-Day--today{
  background: #0089CC;
  color: #fff !important;
} */
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover{
  background: #0089CC !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
  background: #0089CC !important;
}
.DayPicker-Caption > div{
  font-family: 'Campton Bold' !important;
  color: #505050 !important;
}
.DayPicker-NavButton--next{
  /* background: url(./assets/img/calendar-down-arrow.svg)no-repeat !important; */
  background-size: contain;
  width: 24px !important;
  height: 25px !important;
}
.DayPicker-NavButton--prev{
  /* background: url(./assets/img/calendar-up-arrow.svg)no-repeat !important; */
  background-size: contain;
  width: 24px !important;
  height: 25px !important;
  margin-right: 2.5em !important;
}
.DayPicker-NavButton{
  right: 5px !important;
}
.DayPicker-Weekday{
  color: #0089CC !important;
  font-size: 16px !important;
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover{
  color: #505050 !important;
}
.timeTable .sl-scroll {
  min-height: 50px;
}
.timeSlot a {
  font-size: 15px;
  color: #003F63;
  border: 1px solid #C7D1DE;
  border-radius: 6px;
  padding: 9px;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.125rem;
  
}
.timeSlot a.active {
  background: #0089cc;
  color: #fff;
}
.timeSlot a:hover{
  color: #0089CC;
}
.timeTable h4 {
  font-size: 16px;
  font-family: 'Campton Bold';
  color: #505050;
  vertical-align: middle;
  margin-bottom: 5px;
}
.timeSlot {
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0.875rem;
}
.avail_slot .timeSlot{
  margin-bottom: 0 !important;
}
.avail_slot .timeTable{
  margin-top:7px;
}
@media (max-width:991px){
  .eventList{
    overflow-y: auto;
}
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.jas {
  justify-content: space-between;
}
