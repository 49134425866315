.actionIcon{
    margin-bottom: 7px;
}
.status_grid .active_bx {
    padding: 2px 20px;
}
.status_grid .active_bx label label {
    display: inline-block !important;
}
.paginationBx {
    margin-top: 15px !important;
}
.btn-group-sm>.btn, .btn-sm {
    padding: 0.25rem 0.4rem;
}
.timebox h3{
    font-size: 16px;
}
.profileSL {
    margin-bottom: 20px;
    margin-top: 20px;
}
.cp-heading{
    padding-left: 20px;
}
.setting_form {
    padding: 5.813rem 0 !important;
    max-width: 26.563rem !important;
}
.services table tbody tr td {
    padding-left: 25px !important;
}
.services .totalSvs {
    padding: 2px 20px;
}
.custom-other-main .other-flex .bg-cyano {
    background: #1BAFD0;
    color: #ffffff;
    border-radius: 8px;
}
.custom-other-main .other-flex .bg-darkOrange {
   background-color: #FD636B;
}
.custom-other-main .other-flex .bg-lightBlue {
   background-color: #FFB902;
}
.custom-other-main .other-flex .bg-NewRequest {
   background-color: #3BE8B0;
}
.custom-other-main .other-flex .bg-approve {
   background-color: #6967CE;
}
.custom-other-main .other-flex .actionIcon {
    margin-bottom: 0px;
}
.custom-other-main .other-flex .total-count{
    display: block;
}

@media screen and (max-width:1920px) and (min-width: 767px){
.custom-other-main .other-flex .flex-fill {
    flex: 0 0 16% !important;
 }
}

@media (max-width: 767px){
.other-flex {
    margin: 0 10px;
}
.custom-other-main .margin_q{
    margin-left: 16px;
}
.custom-other-main table tbody tr td {
    padding: 5px 15px !important;
}
.billTo {
    margin-left: 0;
}
.margin_four{
    margin-left: 0px !important;
}

}

.view_payment table tbody tr td {
    padding: 5px 15px !important;
}
.slHeading-sub{
   border-bottom: none;
    padding-left: 15px;
    margin-top: 30px;
    margin-bottom: 5px;
}
.appointSlot hr{
    border-top: 2px solid #DDEEFB !important;
}
.appointSlot .flex.time-list .flexbx p {
   color: #003F63;
}
.legalDocUpdateList table tbody tr td,th{
    padding: 5px 15px !important;
 
}
.legalDocUpdateList table tbody tr{
    padding: 5px 15px !important;
}
.l_newrequest table tbody tr td {
    padding: 5px 15px !important;
}

.cms p{
    color: #8A8A8A;
}
.headingClr{
    color:#0089CC ;
}
.sidebar-layout{
    margin-top: 7px;
}
.setting_form .sl-btns button {
   
    width: 170px !important;
    height: 42px !important;
  
}
.billTo {
    margin-left: 16px;
}
.view_payment .cn-btns button {
    margin-right: 40px;
    width: 120px;
    height: 43px;
}
.custom-pay-info{
   padding-left: 32px;
}
.custom-pay-info p{
  color: #6C6F70;
}
.otherTabs .nav-tabs {
margin-left: 16px;
}
.data-table {
   padding: 15px;
   margin-top: 0px;
}
.margin_four{
    margin-left: 4px;
}
.custom-payments .sl-jas {
    align-items: baseline;
    justify-content: space-between;
}
.custom-payments .sl-bx {
    padding: 10px;
}
.custom-payments .sl-jas h6{
    line-height: 0;
}
.total-clr {
   color: #8A8A8A;
}
.custom-margin table tbody tr td {
    padding: 5px 15px !important;
  
}
.custom_law_detail .Rectangle{
    background-color: inherit !important;
    box-shadow: inherit;
}

.custom_law_detail button{
    background-color: #fff;
}

.custom_law_detail{
    border-radius: 0px 10px 10px 0px;
    background-color: #ffffff !important;
   
    border:1px solid #BFCED8;
    width: 50%;
    text-align: center;
}
.custom_law_detail2{
    border-radius: 10px 0px 0px 10px;
    background-color: #ffffff !important;
   
    border:1px solid #BFCED8;
    width: 50%;
    text-align: center;
}
.custom_law_detail2 .btn-active{
    background-color: #ffffff !important;
    color: #003F63 !important;
    border-radius: 0px 10px 10px 0px;
}
.custom_law_detail .btn-active{
   border-radius: 0px 10px 10px 0px !important;
}
.btn:focus {
    outline: none;
    box-shadow: none;
  }
.custom_law_detail button{
    width: 100%;
    border-radius: 0px 10px 10px 0px !important;
}
.custom_law_detail2 button{
   width: 100%;
   border-radius: 10px 0px 0px 10px !important;
}
.pl_35{
    padding-left: 35px;
}
.ard_row {
    margin-left: 16px;
}
.cms table tbody tr td {
    padding: 5px 15px !important;
}
.cms .action a {
    padding: 0px 0px !important;
}
 .cms .sl-modal-footer .save-btn {
    border-radius: 6px !important;
}
table tbody tr td img {
    object-fit: cover;
}
button.close{
    line-height: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #E04F5F;
    font-weight: 500 !important;
}
.close span{
    color: #ffffff;
}
/* .relevantDocs_pdf .sl-icon svg{
    width: 35px !important;
    height: 35px !important;
    margin-right: 2px;
} */
.clients_filter .btn-outline-secondary{
    background-color: inherit;
    border: 1px solid #C8C8C8;
    border-radius: 3px;
    color:#6c757d ;
}
.clients_filter .btn-outline-secondary:hover{
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.clients_filter .btn-outline-info{
    background-color: inherit;
    border: 1px solid #C8C8C8;
   color: #17a2b8;
    border-radius: 3px;
}
.clients_filter .btn-outline-info:hover{
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.agencyType table tbody tr td {
    padding: 5px 15px !important;
}